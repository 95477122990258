
<script setup>
import {ref, onMounted, computed, watch} from 'vue'
import { useRoute } from 'vue-router'
import {useCart} from '@/store/pinia'
import { storeToRefs } from 'pinia';
import axios from 'axios';

const {params} = useRoute();
const cart = useCart();
const {cartItems, loading, loading2} = storeToRefs(cart);


// This section for variable *****************************************************
const orderDetails          = ref({});
const order_is_paid         = ref()
const delivery_gateway_id   = ref()
const deliverCharge         = ref()
const order_status_id       = ref()
const order_district        = ref()
const order_address         = ref()
const customer_name         = ref();
const phone_number          = ref();

const quantityInput     = ref(1);
const sizeId            = ref();
const variationPrice    = ref();
const cartPrice         = ref();
const changeAblePrice   = ref();
const orderId           = ref(params.id);

const search_name       = ref('');
const hideSearchItem    = ref();
const productInfo       = ref();
const special_discount  = ref();

// array variabel
const productPrices     = ref([]);
const delivery_area     = ref([]);
const statuses          = ref([]);
const searchData        = ref([]);
const singleProductSizeId = ref([]);
const updateQuantittyItem = ref([]);

watch(cartItems, (newCartItems, oldCartItems) => {
      singleProductSizeId.value = newCartItems.map(item => item.size_id);
      updateQuantittyItem.value = newCartItems.map(item => item.quantity);
}, { deep: true });

// This area for Api Call***************************************************************

const getOrderDetails = async() =>{
    try {
        const res = await axios.get(`/admin/orders/${params.id}`);
        orderDetails.value = res.data.result

        order_is_paid.value         = res.data.result.is_paid==false? 0:1;
        delivery_gateway_id.value   = res.data.result.dg_id
        order_status_id.value       = res.data.result.status_id
        order_district.value        = res.data.result.district
        order_address.value         = res.data.result.address_details
        customer_name.value         = res.data.result.customer_name
        phone_number.value          = res.data.result.phone_number
        deliverCharge.value         = res.data.result.delivery_charge
        special_discount.value      = res.data.result.special_discount
    } catch (error) {
        console.log(error);
    }
}

const getDeliveryArea = async() =>{
    try {
        const res = await axios.get(`/delivery-gateway`);
        delivery_area.value = res.data.result
    } catch (error) {
        console.log(error);
    }
}

const getStatus = async() =>{
    try {
        const res = await axios.get(`/admin/statuses`);
        statuses.value = res.data.result.data
    } catch (error) {
        console.log(error);
    }
}

const getProducts = async() =>{
    try {
        const res = await axios.get(`/admin/products?name=${search_name.value}`);
        searchData.value = res.data.result.data
        hideSearchItem.value = false;
    } catch (error) {
        console.log(error);
    }
}

const getPrices = async(sizeId) =>{
    try {
        const res = await axios.get(`/products/${productInfo.value.id}/${sizeId}`);
        variationPrice.value = res.data.result;
    } catch (error) {
        console.log(error);
    }
}

const getDeliveryAmount = async() =>{
    try {
    const res = await axios.get(`/delivery-gateway/price/${delivery_gateway_id.value}`);
    deliverCharge.value = res.data.result.delivery_fee
    } catch (error) {
    console.log(error);
    }
}
// This area for Function Call**********************************************************
const deleteCart = (index) =>{
    cart.destroy(index);
}

const formattedCreatedAt = computed(() => {
  if (!orderDetails.value.created_at) {
    return '';
  }
  const createdAt = new Date(orderDetails.value.created_at);
  const options = {
    year  : 'numeric',
    month : 'long',
    day   : 'numeric',
    hour  : '2-digit',
    minute: '2-digit',
  };
  return createdAt.toLocaleString(undefined, options);
});

const getSearchProduct = (product) =>{
    productInfo.value    = product
    productPrices.value  = product.product_prices
    search_name.value    = product.name
    hideSearchItem.value = true;
}

watch([() => params.id], ([newId]) => {
    orderId.value = newId;
    // Call the function to fetch data
    getData();
});


const getData = () =>{
    cart.getData(orderId.value);
}

const updateSizeItem = async(index,itemId, sizeID) => {
    try {
        const res = await axios.get(`/products/${itemId}/${sizeID}`);
        changeAblePrice.value = res.data.result;
    } catch (error) {
        console.log(error);
    }
    cart.updateSizeItem(index, {
        item_id       : cartItems.value[index].item_id,
        name          : cartItems.value[index].name,
        price         : changeAblePrice.value.offer_price == 0 ? changeAblePrice.value.mrp: changeAblePrice.value.offer_price,
        buy_price     : changeAblePrice.value.buy_price,
        sell_price    : changeAblePrice.value.sell_price,
        mrp           : changeAblePrice.value.mrp,
        discount      : changeAblePrice.value.mrp - changeAblePrice.value.offer_price,
        image         : cartItems.value[index].image,
        size_id       : sizeID,
        quantity      : cartItems.value[index].quantity,
        product_prices: cartItems.value[index].product_prices,
        pivot         : cartItems.value[index].pivot,
    })
}

const updateItemQuantity = async(index, quantity) => {
    cart.updateCartItemQuantity(index, {
        item_id       : cartItems.value[index].item_id,
        name          : cartItems.value[index].name,
        price         : cartItems.value[index].price,
        buy_price     : cartItems.value[index].buy_price,
        sell_price    : cartItems.value[index].sell_price,
        mrp           : cartItems.value[index].mrp,
        discount      : cartItems.value[index].discount,
        image         : cartItems.value[index].image,
        size_id       : cartItems.value[index].size_id,
        quantity      : quantity,
        product_prices: cartItems.value[index].product_prices,
        pivot         : cartItems.value[index].pivot,
    })
}

function addToCart(tProduct){
    if(variationPrice.value != null){
      if(variationPrice.value.offer_price !=0){
          cartPrice.value = variationPrice.value.offer_price
      }else{
          cartPrice.value = variationPrice.value.mrp
      }
      cart.addToCart({
        item_id       : tProduct.id,
        name          : tProduct.name,
        buy_price     : variationPrice.value.buy_price,
        sell_price    : cartPrice.value,
        price         : cartPrice.value,
        mrp           : variationPrice.value.mrp,
        discount      : variationPrice.value.mrp - variationPrice.value.offer_price,
        image         : tProduct.image,
        size_id       : sizeId.value,
        quantity      : quantityInput.value,
        product_prices: tProduct.product_prices,
        pivot         : tProduct.pivot,
    });
    }else{
      if(tProduct.offer_price !=0){
          cartPrice.value = tProduct.offer_price
      }else{
          cartPrice.value = tProduct.mrp
      }
      cart.addToCart({
        item_id       : tProduct.id,
        name          : tProduct.name,
        buy_price     : tProduct.buy_price,
        sell_price    : cartPrice.value,
        price         : cartPrice.value,
        mrp           : tProduct.mrp,
        discount      : tProduct.mrp-tProduct.offer_price,
        image         : tProduct.image,
        size_id       : '',
        quantity      : quantityInput.value,
        product_prices: tProduct.product_prices,
        pivot         : tProduct.pivot,
      });
    }
    search_name.value = '';
    quantityInput.value = 1;
    sizeId.value = '';
    productInfo.value = '';
}

const updateOrder = () =>{
    cart.updateOrder(params.id, {
        customer_name   : customer_name.value,
        phone_number    : phone_number.value,
        address_details : order_address.value,
        district        : order_district.value,
        status_id       : order_status_id.value,
        is_paid         : order_is_paid.value,
        dg_id           : delivery_gateway_id.value,
        special_discount: special_discount.value,
        delivery_charge : deliverCharge.value,
    });
}


// Total Price Calculation *****************************

const totalPrice = computed(() => {
    var totalSellPrice =  cartItems.value.reduce((total, item) => {
        return total + (item.price * item.quantity);
    }, 0);
   
    var totalMrp =  cartItems.value.reduce((total, item) => {
        return total + (item.mrp * item.quantity);
    }, 0);
   
    var totalDiscount =  cartItems.value.reduce((total, item) => {
        return total + (item.discount * item.quantity);
    }, 0);

    return  {
        mrp_price : totalMrp,
        sell_price : totalSellPrice,
        discount_price : totalDiscount,
    };
});

onMounted(() => {
    getData();
    getStatus();
    getOrderDetails();
    getDeliveryArea();
})
</script>



<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="bg-inner cart-section order-details-table">
            <div class="row">
                <div class="col-md-12">
                    <div class="card-header-2 d-flex justify-content-between">
                        <h5>Order Information</h5>
                        <router-link :to="{name:'order_list'}" class="btn btn-info"><i class="fa fa-arrow-left"></i> Back</router-link>
                    </div>
                    <div style="border-top:1px solid rgb(187, 187, 187);border-bottom:1px solid rgb(187, 187, 187);padding:5px 0">
                        <h5><span class="text-danger"    style="font-weight: 700;">Order Id :</span>{{ orderDetails.id }} / <span class="text-danger" style="font-weight: 700;">Order Date :</span> {{formattedCreatedAt}} / <span class="text-danger" style="font-weight: 700;">Order Name :</span>  {{orderDetails.customer_name}}</h5>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-3 box-shadow">
                    <div class="mb-4">
                        <label for="">Payment Status</label>
                        <select name="" id="" class="form-control" v-model="order_is_paid">
                            <option value='1'>Paid</option>
                            <option value='0'>Unpaid</option>
                        </select>
                    </div>
                    <div class="mb-4">
                        <label for="">Delivery Area</label>
                        <select name="" id="" class="form-control" v-model="delivery_gateway_id" @change="getDeliveryAmount">
                            <option value="null" v-if="delivery_gateway_id==null">Free Delivery</option>
                            <option v-for="(area, index) in delivery_area" :key="index" :value="area.id">{{ area.name }}</option>
                        </select>
                    </div>
                    <div class="mb-4">
                        <label for="">Order Status</label>
                        <select name="" id="" class="form-control" v-model="order_status_id">
                            <option v-for="(status, index) in statuses" :key="index" :value="status.id">{{status.name}}</option>
                        </select>
                    </div>
                    <div class="mb-4">
                        <label for="">District</label>
                        <input type="text" class="form-control" placeholder="Enter Customer District Name" v-model="order_district">
                    </div>
                    <div class="mb-4">
                        <label for="">Order Address</label>
                        <input type="text" class="form-control" placeholder="House # 10, Road # 17, Block - C, Mirpur , Dhaka-1216" v-model="order_address">
                    </div>
                    <div class="mb-4">
                        <label for="">Phone Number</label>
                        <input type="text" class="form-control" placeholder="+880 100 000 0000" v-model="phone_number">
                    </div>
                    <div class="mb-4">
                        <label for="">Name</label>
                        <input type="text" class="form-control" placeholder="Name" v-model="customer_name">
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="box-shadow">
                        <div class="row mb-3">
                            <div class="col-md-7">
                                <label for="">Search Product</label>
                                <input type="text" class="form-control" placeholder="Search Product..." v-model="search_name" @input="getProducts">
                                <ul class="search-data" v-if="search_name != ''">
                                    <li v-for="(product, index) in searchData" :key="index" :hidden="hideSearchItem">
                                        <div @click="getSearchProduct(product)" class="product-info">
                                            <div class="row search-row">
                                                <div class="col-md-1 col-sm-2 search-img">
                                                    <img :src="product.image" alt="" style="border-radius: 5px;">
                                                </div>
                                                <div class="col-md-7 col-sm-5 search-details">
                                                    <p style="color:#000; margin: 0;line-height: 1.2;">{{ product.name }}</p>
                                                    <span style="font-size: 12px;"><span style="color: #000; font-weight: 500;">Category : </span> {{ product.category.name }}</span>
                                                </div>
                                                <div class="col-md-4 col-sm-5 search-price">
                                                    <div>
                                                        <span class="product-price" v-if="product.mrp !=0" style="font-weight: 400;">
                                                            <del v-if="product.offer_price !=0">{{product.mrp}} tk </del>
                                                            <span>{{ product.offer_price !=0? product.offer_price : product.mrp  }} tk</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-2">
                                <label for="">Select Size</label>
                                <select name="" id="" class="form-control" v-model="sizeId" @change="getPrices(sizeId)">
                                    <option v-for="(variations, index) in productPrices" :key="index" :value="variations.id">{{variations.name}}</option>
                                </select>
                            </div>
                            <div class="col-md-2">
                                <label for="">Quantity</label>
                                <input type="number" class="form-control" v-model="quantityInput">
                            </div>
                            <div class="col-md-1 d-flex align-items-end text-end">
                                <button class="btn btn-info" @click.prevent="productInfo?addToCart(productInfo): ''"><i class="fas fa-spinner fa-spin" v-if="loading2"></i> Add</button>
                            </div>
                        </div>
                        <div class="table-body">
                            <table class="table table-bordered">
                                <thead>
                                    <tr class="product_list_head">
                                        <th>Image</th>
                                        <th>Name</th>
                                        <th>Size</th>
                                        <th>Qtn</th>
                                        <th>Price</th>
                                        <th>Total Price</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in cartItems" :key="index">
                                        <th scope="row">
                                            <img :src="item.image" alt="" width="50">
                                        </th>
                                        <td>{{ item.name }}</td>
                                        <td>    
                                            <select name="" id="" class="form-control" v-model="singleProductSizeId[index]" v-if="item.product_prices.length>0" @change="updateSizeItem(index,item.item_id, singleProductSizeId[index])">
                                                <option v-for="(sizeItem, index2) in item.product_prices" :key="index2" :value="sizeItem.id">{{ sizeItem.name }}</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input type="number" class="form-control" v-model="updateQuantittyItem[index]" @input="updateItemQuantity(index, updateQuantittyItem[index])">
                                        </td>
                                        <td>{{ item.price }} tk</td>
                                        <td>{{ item.price*updateQuantittyItem[index] }} tk</td>
                                        <td><i class="fas fa-trash text-danger" @click="deleteCart(index)"></i></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="row">
                            <div class="col-md-8"></div>
                            <div class="col-md-4 mt-4">
                                <div class="d-flex justify-content-between order-info mb-2">
                                    <label for="">Items Regular Price</label>
                                    <span>tk. {{ totalPrice.mrp_price }}</span>
                                </div>
                                <div class="d-flex justify-content-between order-info mb-2">
                                    <label for="">Items Discount Price</label>
                                    <span>tk. {{ totalPrice.sell_price }}</span>
                                </div>
                                <div class="d-flex justify-content-between order-info mb-2">
                                    <label for="">Discount Amount</label>
                                    <span>tk. {{ totalPrice.discount_price }}</span>
                                </div>
                                <div class="d-flex justify-content-between order-info mb-2">
                                    <label for="">Delivery Charge</label>
                                    <span><input type="text" class="form-control" style="width:100px;padding:0 10px;" placeholder="00.00 tk" v-model="deliverCharge"></span>
                                </div>
                                <div class="d-flex justify-content-between align-items-end order-info mb-2">
                                    <label for="">Spacial Discount</label>
                                    <span><input type="text" class="form-control" style="width:100px;padding:0 10px;" placeholder="00.00 tk" v-model="special_discount"></span>
                                </div>
                                <div class="d-flex justify-content-between order-info mb-2 bg-danger">
                                    <label for="">Total Price</label>
                                    <span>tk. {{ parseFloat(totalPrice.sell_price) + parseFloat(deliverCharge) - parseFloat(special_discount?special_discount:0)}}</span>
                                </div>
                                <div class="d-flex justify-content-between order-info mb-2">
                                    <button @click="updateOrder()" class="btn btn-info" style="width:100%"><i class="fas fa-spinner fa-spin" v-if="loading"></i> Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <!-- section end -->
        </div>
      </div>
    </div>
  </div>
</template>

<style>
    .search-data{
        position: absolute;
        width: 100%;
        z-index: 9;
        top: 42px;
        left: 0;
        background-color: #F5F5F5;
        border-radius: 10px;
        border-bottom: 2px ;
    }
    .product-info{
        display: block;
        padding: 10px 10px;
        cursor: pointer;
    }
    .search-data li {
        border-top: 1px solid rgb(220, 221, 220);
        position: relative;
        overflow: hidden !important;
    }

    .search-data li:first-child {
        border-top: none; /* Remove the top border from the first li */
    }
    .featured_label{
        float: right;
        margin: -2px -48px 0 0;
        background-color: #E86121;
        color: #fff;
        padding: 0 40px;
        font-size: 13px;
        top: 0;
        right: 0;
        transform: rotate(40deg);
    }
    .search-img img{
        max-width: 100%;
    }
    .box-shadow{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 20px;
        padding: 40px 30px;
    }
    .product_list_head{
        background-color: #F5F5F5;
    }
    .table-body{
        max-width: 100% !important;
        overflow-x: scroll;
    }
    .fa-trash{
        cursor: pointer;
    }
    .order-info{
        background-color: #e0e0e0;
        padding: 5px 10px;
    }
    .order-info label{
        margin: 0;
    }
</style>
