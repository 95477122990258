<script setup>
  import axios from 'axios';
  import {ref, onMounted, computed, watch} from 'vue';
  import { Bootstrap5Pagination } from 'laravel-vue-pagination';
  
  const orderData  = ref([]);
  const orderIds   = ref([]);
  const searchText = ref('');
  const is_paid    = ref(false)
  const checkAll = ref(false);

  const getOrders = async(page = 1) => {
    try{
      const res = await axios.get(`/admin/orders?page=${page}&search_key=${searchText.value}`);
      orderData.value = res.data.result
    }catch(error){
      console.log(error);
    }
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString('en-US', options);
  }

  const deleteOrder = async(id) =>{
    const res = await axios.post(`/admin/orders/${id}`);
    getOrders();
  }

const toggleAll = () => {
  if (checkAll.value) {
    orderIds.value = orderData.value.data.map(order => order.id);
  } else {
    orderIds.value = [];
  }
}

watch(
  [ () => orderIds.value ], ([newOrderIds]) => {
    localStorage.setItem('orderIds', JSON.stringify(newOrderIds));
  }
);

onMounted(() => {
  getOrders();
});
</script>


<template>
  <div>
    <div class="table-responsive table-desi">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-between">
          <div class="d-flex"><input type="text" class="form-control mb-2" placeholder="Search Order . . ." v-model="searchText"><button class="mb-2 btn btn-info" @click="getOrders">Search</button></div>
          <span>
            <router-link :to="{name: 'multiple_invoice'}" class="btn btn-info me-2 mb-2" style="letter-spacing:1px;" :disabled="orderIds.length==0">Multiple Invoice</router-link>
            <router-link :to="{name: 'order_reports'}" class="btn btn-info mb-2">Order Reports</router-link>
          </span>
        </div>
      </div>
      <table class="table table-bordered">
        <thead>
          <tr class="bg-dark">
            <th class="text-light" style="width: 5%;"><input type="checkbox" v-model="checkAll" id="check" @change="toggleAll"> <label for="check">All</label></th>
            <th class="text-light" style="width: 5%;">SL</th>
            <th class="text-light" style="width: 5%;">Order Id</th>
            <th class="text-light" style="width: 15%;">Status</th>
            <th class="text-light" style="width: 10%;">Phone Number</th>
            <th class="text-light" style="width: 10%;">Date</th>
            <th class="text-light" style="width: 10%;">Payment Method</th>
            <th class="text-light" style="width: 10%;">Currier</th>
            <th class="text-light" style="width: 5%;">Is Paid</th>
            <th class="text-light" style="width: 10%;">Payable Amount</th>
            <th class="text-light" style="width: 15%;">Action</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(order, index) in orderData.data" :key="index">
            <td><input type="checkbox" :value="order.id" v-model="orderIds"></td>
            <td>{{index+1}}</td>
            <td>{{order.id}}</td>
            <td>
              <span v-if="order.current_status.name=='Submitted'" class="payment_status" style="background-color:#1175AE;color:#fff;">{{  order.current_status.name  }}</span>
              <span v-else-if="order.current_status.name=='On Hold'" class="payment_status" style="background-color:#C98209;color:#fff;">{{  order.current_status.name  }}</span>
              <span v-else-if="order.current_status.name=='Under Verified'" class="payment_status" style="background-color:#ddb063;color:#fff;">{{  order.current_status.name  }}</span>
              <span v-else-if="order.current_status.name=='Under Processing'" class="payment_status" style="background-color:#ddb063;color:#fff;">{{  order.current_status.name  }}</span>
              <span v-else-if="order.current_status.name=='On Way'" class="payment_status" style="background-color:#06d14a;rgb(182, 124, 25)fff; color:#fff;">{{  order.current_status.name  }}</span>
              <span v-else-if="order.current_status.name=='Completed'" class="payment_status" style="background-color:#086928;rgb(182, 124, 25)fff; color:#fff;">{{  order.current_status.name  }}</span>
              <span v-else-if="order.current_status.name=='Canceled'" class="payment_status" style="background-color:#e03602;rgb(182, 124, 25)fff; color:#fff;">{{  order.current_status.name  }}</span>
              <span v-else-if="order.current_status.name=='Returned'" class="payment_status">{{  order.current_status.name  }}</span>
              <span v-else-if="order.current_status.name=='Delivered'" class="delivered">{{  order.current_status.name  }}</span>
            </td>
            <td>{{  order.phone_number  }}</td>

            <td>{{  formatDate(order.created_at)  }}</td>

            <td>{{ order.payment_gateway.name=='Cash on Delivery'?'COD' : 'Online' }}</td>
            <td :class="{
              'order-success': order.courier_name =='Steadfast',
              'order-cancle' : order.courier_name =='Pathao'
            }">
              <span>{{ order.courier_name }}</span>
            </td>
            <td class="order-success" v-if="order.is_paid">
              <span>Paid</span>
            </td>
            <td class="order-cancle" v-else>
              <span>Unpaid</span>
            </td>

            <td>{{  order.payable_price  }} tk</td>
            <td>
              <ul class="d-flex">
                <li class="me-3">
                  <router-link :to="{name:'order_detail', params:{id: order.id}}"><span class="lnr lnr-eye text-danger"></span></router-link>
                </li>
                <li class="me-3">
                  <router-link :to="{name:'order_invoice', params:{id: order.id}}"><i class="fas fa-print text-info"></i></router-link>
                </li>

                <li class="me-3">
                  <router-link :to="{name:'edit_order', params:{id: order.id}}"><i class="fas fa-edit text-success"></i></router-link>
                </li>

                <li class="me-3">
                  <router-link :to="{name:'shipped_pathao', params:{id: order.id}}"><img src="../../../../assets/images/pathao_icon.png" width="20" alt=""></router-link>
                </li>
                <li class="me-3">
                  <router-link :to="{name:'shipped_streetfast', params:{id: order.id}}"><img src="../../../../assets/images/streetfast.png" width="20" alt=""></router-link>
                </li>
                <li class="me-3">
                  <span @click="deleteOrder(order.id)"><i class="fas fa-trash text-danger"></i></span>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination-box p-4" style="margin-bottom:70px;">
        <nav class="ms-auto me-auto" aria-label="...">
          <ul class="pagination pagination-primary">
            <Bootstrap5Pagination
              :data="orderData"
              @pagination-change-page="getOrders"
            />
          </ul>
        </nav>
    </div>
    </div>
  </div>
</template>


<style>
  .order-success span, .delivered{
    background-color: rgba(66, 186, 150, 0.2);
    color: #42ba96;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 700;
    position: relative;
  }
  .order-cancle span, .payment_status{
    background-color: rgba(207, 20, 43, 0.2);
    color: #cf142b;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 700;
    position: relative;
  }
  .table th{
    font-size: 16px !important;
    text-align: center;
  }
</style>
