
  <script setup>
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  import axios from "axios";
  import {ref, onMounted, watch} from 'vue';
  import router from "@/router";
  import { useRoute } from "vue-router";

  const {params}          = useRoute();
  const editor            = ClassicEditor;
  const editorConfig      = ref({});
  const categoryData      = ref([]);
  const subCategoryData   = ref([]);
  const brandData         = ref([]);
  const sizeData          = ref([]);
  const errors            = ref({});
  const buy_price         = ref([]);
  const mrp_price         = ref([]);
  const sell_price        = ref([]);
  const sizes             = ref([]);
  const isDefault         = ref([]);
  const dynamicInputs     = ref([]);
  const images            = ref([]);
  const imagePreview      = ref([]);
  const isLoading         = ref(false);
  const brand             = ref(null);
  const buyPrice          = ref(0);
  const mrpPrice          = ref(0);
  const offerPrice        = ref(0);
  const stock             = ref(10);
  const isFreeShipping    = ref(0);
  const sub_category      = ref('');
  const category          = ref('');
  const sku               = ref('');
  const editorData        = ref('');
  const editorBnData      = ref('');
  const editorShortData   = ref('');
  const editorShortBnData = ref('');
  const meta_title        = ref('');
  const meta_keywords     = ref('');
  const meta_description  = ref('');
  const name              = ref();
  const bn_name           = ref();
  const status            = ref('active');
  const type              = ref('recent-product');

  const getCategory = async() => {
    try {
      const resCategory = await axios.get('/admin/categories');
      categoryData.value = resCategory.data.result.data
    } catch (error) {
    }
  }

  const getSize = async() => {
    try {
      const resSize = await axios.get('/admin/sizes');
      sizeData.value = resSize.data.result.data
    } catch (error) {
      
    }
  }

  const getBrand = async() => {
    try {
      const resBrand = await axios.get('/admin/brands');
      brandData.value = resBrand.data.result.data
    } catch (error) {
      
    }
  }

  const getProduct = async() =>{
    try {
      const resProduct = await axios.get(`/admin/products/${params.id}`);
            name.value              = resProduct.data.result.name;
            bn_name.value           = resProduct.data.result.bn_name;
            status.value            = resProduct.data.result.status;
            type.value              = resProduct.data.result.type;
            category.value          = resProduct.data.result.category_id;
            sub_category.value      = resProduct.data.result.sub_category_id;
            brand.value             = resProduct.data.result.brand_id;
            buyPrice.value          = resProduct.data.result.buy_price;
            buyPrice.value          = resProduct.data.result.buy_price;
            mrpPrice.value          = resProduct.data.result.mrp;
            offerPrice.value        = resProduct.data.result.offer_price;
            stock.value             = resProduct.data.result.current_stock;
            isFreeShipping.value    = resProduct.data.result.free_shipping;
            sku.value               = resProduct.data.result.sku;
            images.value            = resProduct.data.result.images;
            dynamicInputs.value     = resProduct.data.result.product_prices;
            meta_title.value        = resProduct.data.result.meta_title;
            meta_keywords.value     = resProduct.data.result.meta_keywords;
            meta_description.value     = resProduct.data.result.meta_description;

            editorData.value        = resProduct.data.result.description          != null ? resProduct.data.result.description : '';
            editorBnData.value      = resProduct.data.result.bn_description       != null ? resProduct.data.result.bn_description : '';
            editorShortData.value   = resProduct.data.result.short_description    != null ? resProduct.data.result.short_description : '';
            editorShortBnData.value = resProduct.data.result.bn_short_description != null ? resProduct.data.result.bn_short_description : '';

      const sizeIds                 = resProduct.data.result.product_prices.map(s=>s.pivot.size_id);
            sizes.value             = sizeIds
      const BuyPrice                = resProduct.data.result.product_prices.map(s=>s.pivot.buy_price);
            buy_price.value         = BuyPrice
      const Default                 = resProduct.data.result.product_prices.map(s=>s.pivot.is_default);
            isDefault.value         = Default
      const MrpPrice                = resProduct.data.result.product_prices.map(s=>s.pivot.mrp);
            mrp_price.value         = MrpPrice
      const OfferPrice              = resProduct.data.result.product_prices.map(s=>s.pivot.offer_price);
            sell_price.value        = OfferPrice

    } catch (error) {
      console.log(error);  
    }
  }


  watch(category, async (newCategory) => {
    if (newCategory) {
      await getSubCategory();
    }
  });

  const getSubCategory = async() => {
    try {
      const res = await axios.get(`/admin/get-subcategories/${category.value}`);
      subCategoryData.value = res.data.result
    } catch (error) {
    }
  }

const addInputField = () =>{
  dynamicInputs.value.push('');
  buyPrice.value   = 0;
  mrpPrice.value   = 0;
  offerPrice.value = 0;
}

const handleFileChange = (e) => {
  images.value = e.target.files;
  
  for (const image of images.value) {
    let reader = new FileReader();
    reader.addEventListener("load", function () {
      imagePreview.value.push(reader.result);
    }, false);

    if (/\.(jpe?g|jpg|png|gif)$/i.test(image.name)) {
      reader.readAsDataURL(image);
    }
  }
}

const closeItemVariation = (index) =>{
  dynamicInputs.value.splice(index, 1)
  mrp_price.value.splice(index, 1)
  buy_price.value.splice(index, 1)
  sell_price.value.splice(index, 1)
  sizes.value.splice(index, 1)
}

const submit = async() =>{
  const formData = new FormData();
  formData.append('name', name.value)
  formData.append('bn_name', bn_name.value)
  formData.append('brand_id', brand.value)
  formData.append('category_id', category.value)
  formData.append('sub_category_id', sub_category.value)
  formData.append('current_stock', stock.value)
  formData.append('free_shipping', isFreeShipping.value)
  formData.append('sku', sku.value)
  formData.append('status', status.value)
  formData.append('type', type.value)
  formData.append('description', editorData.value)
  formData.append('bn_description', editorBnData.value)
  formData.append('short_description', editorShortData.value)
  formData.append('bn_short_description', editorShortBnData.value)
  formData.append('offer_price', offerPrice.value)
  formData.append('mrp', mrpPrice.value)
  formData.append('buy_price', buyPrice.value)
  formData.append('meta_title', meta_title.value)
  formData.append('meta_keywords', meta_keywords.value)
  formData.append('meta_description', meta_description.value)
  formData.append('_method', 'put');
  
  if(imagePreview.value !=0){
    formData.append('image', images.value[0]);

    for (const image of images.value) {
      formData.append('gallery_images[]', image);
    }
  }

  let d = 0;
  for (const defa of isDefault.value) {
    formData.append(`product_prices[${d}][is_default]`, defa=='1'?1:0);
    d++;
  }

  let s = 0;
  for (const size of sizes.value) {
    formData.append(`product_prices[${s}][size_id]`, size);
    s++;
  }

  let b = 0;
  for (const buyPrice of buy_price.value) {
    formData.append(`product_prices[${b}][buy_price]`, buyPrice);
    b++;
  }

  let m = 0;
  for (const mrp of mrp_price.value) {
    formData.append(`product_prices[${m}][mrp]`, mrp);
    m++;
  }

  let o = 0;
  for (const offerPrice of sell_price.value) {
    formData.append(`product_prices[${o}][offer_price]`, offerPrice);
    o++;
  }

  try {
    console.log(formData);
    isLoading.value = true;
    const res = await axios.post(`/admin/products/${params.id}`, formData);
    if (res.status ==200) {
      router.push({ name: 'products' });
    } else {
      errors.value = res.data.message;
    }
  }catch (error) {
    console.log(error);
  }finally{
    isLoading.value = false;
  }
}

onMounted(() => {
  getCategory();
  getBrand();
  getSize();
  getProduct();
});

  </script>


  <template>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body">
                <div class="card-header-2 d-flex justify-content-between">
                  <h5>Product Information</h5>
                  <div>
                    <router-link :to="{name:'products'}" class="btn btn-info me-2"><i class="fa fa-arrow-left"></i> Back</router-link>
                    <span class="btn btn-danger" @click="addInputField">Add Product Variations</span>
                  </div>
                </div>

                <form class="theme-form theme-form-2 mega-form" @submit.prevent="submit">
                  <div class="row">
                    <div class="col-md-6 product-variations mb-4 p-4">
                        <div class="mb-4 row align-items-center">
                        <label class="form-label-title col-sm-4 mb-0">Product Name</label>
                        <div class="col-sm-8">
                          <input class="form-control" type="text" placeholder="Product Name" v-model="name"/>
                        </div>
                      </div>

                      <div class="mb-4 row align-items-center">
                        <label class="form-label-title col-sm-4 mb-0">প্রোডাক্টের নাম ( বাংলায় )</label>
                        <div class="col-sm-8">
                          <input class="form-control" type="text" placeholder="প্রোডাক্টের নাম লিখুন.." v-model="bn_name"/>
                          <span class="text-danger" v-for="(error, index) in errors.name" :key="index">{{error}}</span>
                        </div>
                      </div>


                      <div class="mb-4 row align-items-center">
                        <label class="form-label-title col-sm-4 mb-0">Product Status</label>
                        <div class="col-sm-8">
                          <select class="js-example-basic-single w-100" name="status" v-model="status">
                            <option disabled>Select Product Status</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                        </div>
                      </div>

                      <div class="mb-4 row align-items-center">
                        <label class="col-sm-4 col-form-label form-label-title"
                          >Product Type</label
                        >
                        <div class="col-sm-8">
                          <select class="js-example-basic-single w-100" name="type" v-model="type">
                            <option disabled>Select Product Type</option>
                            <option value="top-product">Top Product</option>
                            <option value="feature-product">Fetured Product</option>
                            <option value="recent-product">New Product</option>
                          </select>
                        </div>
                      </div>

                      <div class="mb-4 row align-items-center">
                        <label class="col-sm-4 col-form-label form-label-title">Category</label>
                        <div class="col-sm-8">
                          <select class="js-example-basic-single w-100" name="category" v-model="category">
                            <option disabled style="font-weight:700;" value="title">Category Menu</option>
                            <option v-for="(category, index) in categoryData" :key="index" :value="category.id">{{category.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="mb-4 row align-items-center">
                        <label class="col-sm-4 col-form-label form-label-title">Sub Category</label>
                        <div class="col-sm-8">
                          <select class="js-example-basic-single w-100" name="category" v-model="sub_category">
                            <option disabled style="font-weight:700;" value="title">Category Menu</option>
                            <option v-for="(subCategory, index) in subCategoryData" :key="index" :value="subCategory.id">{{subCategory.name}}</option>
                          </select>
                        </div>
                      </div>

                      <div class="mb-4 row align-items-center">
                        <label class="col-sm-4 col-form-label form-label-title" >Brand</label>
                        <div class="col-sm-8">
                          <select class="js-example-basic-single w-100" name="brand" v-model="brand">
                            <option disabled style="font-weight:700;" value="title">Select Brand</option>
                            <option v-for="(brand, index) in brandData" :key="index" :value="brand.id">{{brand.name}}</option>
                          </select>
                          <!-- <span v-if="errors.brand_id" class="text-danger">{{errors.brand_id[0]}}</span> -->
                        </div>
                      </div>

                      <div class="mb-4 row align-items-center">
                        <label class="col-sm-4 col-form-label form-label-title">Product Stock Quantity</label>
                        <div class="col-sm-8">
                          <input class="form-control" type="number" placeholder="Enter Quantity" name="stock" v-model="stock"/>
                        </div>
                      </div>

                      <div class="mb-4 row align-items-center">
                        <label class="col-sm-4 col-form-label form-label-title">Is Free Shipping</label>
                        <div class="col-sm-8">
                          <select class="from-controll" id="" v-model="isFreeShipping">
                            <option value="0">No</option>
                            <option value="1">Yes</option>
                          </select>
                        </div>
                      </div>

                      <div class="mb-4 row align-items-center">
                        <label class="col-sm-4 col-form-label form-label-title">Product SKU Number</label>
                        <div class="col-sm-8">
                          <input class="form-control" type="text" placeholder="Enter SKU Number" v-model="sku"/>
                        </div>
                      </div>

                      <div class="mb-4 row">
                        <label class="form-label-title col-sm-4 mb-0">Short Description</label>
                        <div class="col-sm-8">
                          <div id="editor">
                            <ckeditor :editor="editor" v-model="editorShortData" :config="editorConfig"></ckeditor>
                          </div>
                        </div> 
                      </div>

                      <div class="mb-4 row">
                        <label class="form-label-title col-sm-4 mb-0">Short Description ( বাংলায় )</label>
                        <div class="col-sm-8">
                          <div id="editor">
                            <ckeditor :editor="editor" v-model="editorShortBnData" :config="editorConfig"></ckeditor>
                          </div>
                        </div> 
                      </div>

                      <div class="mb-4 row">
                        <label class="form-label-title col-sm-4 mb-0">Product Description</label>
                        <div class="col-sm-8">
                          <div id="editor">
                            <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
                          </div>
                        </div> 
                      </div>

                      <div class="mb-4 row">
                        <label class="form-label-title col-sm-4 mb-0">Product Description ( বাংলায় )</label>
                        <div class="col-sm-8">
                          <div id="editor">
                            <ckeditor :editor="editor" v-model="editorBnData" :config="editorConfig"></ckeditor>
                          </div>
                        </div> 
                      </div>

                      <div class="mb-4 row">
                        <div class="mb-4 row align-items-center">
                          <label class="col-sm-4 col-form-label form-label-title">Images</label>
                          <div class="col-sm-8">
                            <input class="form-control form-choose" type="file" id="formFileMultiple" multiple @change="handleFileChange"/><br>
                            <span class="text-danger">Product image size (450px * 400px)</span>
                            <div v-if="imagePreview==0">
                              <div class="d-inline-block gallery-img-box" v-for="(image, index) in images" :key="index">
                                <img :src="image.image" alt="not Found" width="100" style="margin-right:10px;">
                                <!-- <span class="close-btn" @click="deleteImg(index)">X</span> -->
                              </div>
                            </div>
                            <div v-else>
                              <div class="d-inline-block gallery-img-box" v-for="(image, index) in imagePreview" :key="index">
                                <img :src="image" alt="not Found" width="100" style="margin-right:10px;">
                                <!-- <span class="close-btn" @click="deleteImg(index)">X</span> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="mb-4 row">
                        <label class="form-label-title col-sm-4 mb-0">Meta Title</label>
                        <div class="col-sm-8">
                          <div id="editor">
                            <input type="text" class="form-control" placeholder="Enter Meta Title" v-model="meta_title">
                          </div>
                        </div> 
                      </div>
                      <div class="mb-4 row">
                        <label class="form-label-title col-sm-4 mb-0">Meta Keywords</label>
                        <div class="col-sm-8">
                          <div id="editor">
                            <input type="text" class="form-control" placeholder="Enter Meta Keywords" v-model="meta_keywords">
                          </div>
                        </div> 
                      </div>
                      <div class="mb-4 row">
                        <label class="form-label-title col-sm-4 mb-0">Meta Description</label>
                        <div class="col-sm-8">
                          <div id="editor">
                            <textarea cols="30" rows="3" v-model="meta_description" class="form-control" placeholder="Write Meta Description"></textarea>
                          </div>
                        </div> 
                      </div>

                    </div>


                    <div class="col-md-6">
                      <template v-if="dynamicInputs.length > 0">
                        <div class="product-variations mb-4" v-for="(input, index) in dynamicInputs" :key="index">
                          <div class="row align-items-center">
                            <div class="d-flex justify-content-between">
                              <h4 class="text-success" style="font-weight: 700;">Variation {{ index+1 }} Information</h4>
                              <span class="text-danger" style="font-weight: 700; font-size:20px;cursor:pointer;" @click="closeItemVariation(index)">X</span>
                            </div>

                            <div class="col-md-6 mb-2">
                              <label class="form-label-title col-sm-12 mb-0">Is Default</label>
                              <div class="col-sm-12">
                                <select style="width: 100%;" name="" id="" v-model="isDefault[index]">
                                  <option value="1">Yes</option>
                                  <option value="0">No</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-6 mb-2">
                              <label class="form-label-title col-sm-12 mb-0">Product Size</label>
                              <div class="col-sm-12">
                                <select style="width: 100%;" name="" id="" v-model="sizes[index]">
                                  <option value="">Choose a Size</option>
                                  <option v-for="(size, index2) in sizeData" :key="index2" :value="size.id">{{ size.name }}</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-6 mb-2">
                              <label class="form-label-title col-sm-12 mb-0">Product Buy Price</label>
                              <div class="col-sm-12">
                                <input class="form-control" type="text" placeholder="Enter Buy Price" name="buy_price" v-model="buy_price[index]" />
                              </div>
                            </div>

                            <div class="col-md-6 mb-2">
                              <label class="form-label-title col-sm-12 mb-0">Product Regular Price</label>
                              <div class="col-sm-12">
                                <input class="form-control" type="text" placeholder="Enter Regular Price" name="mrp_price" v-model="mrp_price[index]" />
                              </div>
                            </div>

                            <div class="col-md-6 mb-2">
                              <label class="form-label-title col-sm-12 mb-0">Product Offer Price</label>
                              <div class="col-sm-12">
                                <input class="form-control" type="text" placeholder="Enter Offer Price" name="sell_price" v-model="sell_price[index]" />
                              </div>
                            </div>

                          </div>
                        </div>
                      </template>
                      <div class="product-variations mb-4" v-else>
                        <div class="row align-items-center">
                          <div class="col-md-6 mb-2">
                            <label class="form-label-title col-sm-12 mb-0">Product Buy Price</label>
                            <div class="col-sm-12">
                              <input class="form-control" type="text" placeholder="Enter Buy Price" name="buy_price" v-model="buyPrice" />
                            </div>
                          </div>

                          <div class="col-md-6 mb-2">
                            <label class="form-label-title col-sm-12 mb-0">Product Regular Price</label>
                            <div class="col-sm-12">
                              <input class="form-control" type="text" placeholder="Enter Regular Price" name="mrp_price" v-model="mrpPrice" />
                            </div>
                          </div>

                          <div class="col-md-6 mb-2">
                            <label class="form-label-title col-sm-12 mb-0">Product Offer Price</label>
                            <div class="col-sm-12">
                              <input class="form-control" type="text" placeholder="Enter Offer Price" name="sell_price" v-model="offerPrice" />
                            </div>
                          </div>
                          
                        </div>
                      </div>

                    </div>
                    <div class="row">
                      <button class="product-btn">
                        <i v-if="isLoading" class="fas fa-spinner fa-spin"></i> Update Product
                      </button>
                    </div>
                    
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </template>

  <style>
      .product-btn{
      background-color: #06adb9;
      color: #fff;
      padding: 8px 25px;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      border: none;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    .product-btn:hover{
      background-color: #038c96;
    }
    .theme-form select{
      border: none;
    }
    .product-variations{
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border-radius: 15px;
      padding: 20px;
    }
    ::placeholder{
      color: #b6b6b6 !important;
    }
  </style>
